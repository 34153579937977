<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
    />
    <div class="vap-page-header">
      <h1>Image Library</h1>
      <div class="btns-container">
        <VasionButton
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="editImageClick()"
        >
          New Image
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div v-if="imagesLoading">
        <!-- Show white space while waiting for data to load -->
      </div>
      <div
        v-else-if="imageList && imageList.length > 0 && tableData.Columns"
        class="grid-div vasion-html-table-default"
      >
        <VasionTable
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
      <div v-else>No images found.</div>
    </div>
    <VasionGeneralModal
      id="configure-image-dialog"
      modalType="slot"
      :modalTitle="configureImageTitle"
      :sync="showConfigureImageDialog"
      :hideButtons="true"
      :showTopRightCloseButton="false"
      @noButtonClick="cancelConfigureImageClick"
    >
      <ConfigureImage
        :imageToModify="selectedImage"
        @on-image-saved="onImageSaved"
        @cancel-click="cancelConfigureImageClick"
      />
    </VasionGeneralModal>

    <md-dialog id="confirm-image-delete" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog
        :message="deletePromptMessage"
        @noButtonClick="toggleDeleteDialog()"
        @yesButtonClick="imageDeleteConfirmation()"
      />
    </md-dialog>

    <VasionSnackbar
      id="image-library-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import { loaderColor } from "@/assets/js/styleConfig";
import ConfigureImage from "@/components/systemSettings/ConfigureImage.vue";

export default {
  name: "ImageLibrary",
  components: {
    Loading,
    ConfigureImage,
  },
  data: function () {
    return {
      columns: {
        Values: ["_ImageID_", "Name", "Thumbnail", "Apply Timestamp", "_VasionEllipsisButton_"],
        ValueType: "String",
      },
      hiddenColumns: ["_ImageID_"],
      imageId: 0,
      imageName: "",
      imagesLoading: true,
      isLoading: false,
      loaderColor: loaderColor,
      selectedImage: {},
      showConfigureImageDialog: false,
      showDeleteDialog: false,
      showSnackbar: false,
      snackbarImage: true,
      snackbarSubTitle: "",
      snackbarTitle: "",
      sortTableAscending: true,
      sortTableBy: "Name",
      tableData: {},
    };
  },
  computed: {
    configureImageTitle() {
      return Object.keys(this.selectedImage).length ? "Edit Image" : "Create Image";
    },
    deletePromptMessage() {
      return `Are you sure you want to delete the "${this.imageName}" Image?`;
    },
    ellipsisButtonConfig() {
      return this.$store.state.security.ellipsisButtonConfig;
    },
    imageList() {
      return this.$store.state.document.annotationImageList;
    },
  },
  async created() {
    const promise1 = this.$store.dispatch("document/getAnnotationImageList");
    const promise2 = this.$store.dispatch("security/setEllipsisButtonConfig", "image-library");
    await Promise.all([promise1, promise2]);
    this.imagesLoading = false;
    await this.refreshTable();
  },
  methods: {
    cancelConfigureImageClick() {
      this.selectedImage = {};
      this.showConfigureImageDialog = false;
    },
    deleteImageClick(image) {
      this.imageId = image.id;
      this.imageName = image.name;
      this.toggleDeleteDialog();
    },
    editImageClick(image = {}) {
      this.selectedImage = image;
      this.showConfigureImageDialog = true;
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const imageId = payload.item.Values[0];
        const image = this.imageList.find((i) => i.id === imageId);

        switch (payload.buttonId) {
          case "edit-image-button":
            this.editImageClick(image);
            break;
          case "delete-image-button":
            this.deleteImageClick(image);
            break;
          default:
            break;
        }
      }
    },
    async imageDeleteConfirmation() {
      this.toggleDeleteDialog();

      if (this.imageId > 0) {
        this.isLoading = true;
        const response = await this.$store.dispatch("document/deleteAnnotationImage", this.imageId);
        if (response && response?.data?.Value === "True") {
          await this.$store.dispatch("document/getAnnotationImageList");
          this.snackbarTitle = "Image Delete Successful";
          this.snackbarSubTitle = `Deleted image: "${this.imageName}"`;
          this.snackbarImage = true;
          this.showSnackbar = true;
          await this.refreshTable();
        } else {
          this.snackbarTitle = "The image could not be deleted.";
          this.snackbarSubTitle = `Image: "${this.imageName}"`;
          this.snackbarImage = false;
          this.showSnackbar = true;
        }
      }

      this.imageId = 0;
      this.imageName = "";
    },
    async refreshTable() {
      const data = {
        dataList: this.imageList,
        columns: this.columns,
      };
      this.tableData = await this.$store.dispatch("security/setImageTableData", data);
      this.isLoading = false;
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      const imageId = data.Values[0];
      const image = this.imageList.find((i) => i.id === imageId);
      this.editImageClick(image);
    },
    toggleDeleteDialog() {
      this.showDeleteDialog = !this.showDeleteDialog;
    },
    async onImageSaved() {
      this.showConfigureImageDialog = false;
      this.isLoading = true;

      this.showSnackbar = true;
      this.snackbarImage = true;
      this.snackbarTitle = "Success";
      this.snackbarSubTitle = "The image has been successfully saved.";

      await this.$store.dispatch("document/getAnnotationImageList");

      this.refreshTable();
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-div {
  height: calc(100vh - 222px);
  overflow: auto;
}
</style>
