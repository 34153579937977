<template>
  <div class="main-div">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
    />
    <div class="form-div">
      <VasionInput
        v-model="name"
        title="Name"
        class="input-style"
        inputType="top-white"
        required
        :isInErrorState="formFieldErrors.name"
        @input="(val) => (val ? clearFieldError('name') : null)"
      />

      <div v-if="imageReceived && !imageUploaderActive" class="text-center">
        <VasionButton
          id="change-image-button"
          :classProp="'primary'"
          @vasionButtonClicked="changeImageClick()"
        >
          Change image
        </VasionButton>
      </div>
      <div v-if="imageReceived && !imageUploaderActive" class="img-container">
        <img
          :src="`data:image/png;base64,${base64String}`"
          class="existing-image"
          :alt="`Image ${name}`"
        />
      </div>

      <div v-if="!imageReceived || imageUploaderActive" class="upload-container">
        <file-pond
          :acceptedFileTypes="['image/jpeg', 'image/tiff', 'image/bmp', 'image/png']"
          ref="pond"
          class="new-document"
          :beforeAddFile="beforeAddFile"
          :server="serverOptions"
          :label-idle="filePondLabel"
          :allowRemove="true"
          @processfile="afterFileProcessed"
        />
      </div>

      <div class="md-layout">
        <div class="checkbox-div">
          <VasionCheckbox
            id="apply-timestamp"
            name="applyTimestamp"
            :checked="applyTimestamp"
            @change="toggleApplyTimestamp()"
          >
            Apply Timestamp
          </VasionCheckbox>
        </div>
      </div>

      <div class="bottom-div">
        <VasionButton
          id="cancel-button"
          :classProp="'primary-light'"
          @vasionButtonClicked="cancelClick()"
        >
          CANCEL
        </VasionButton>
        <VasionButton
          id="ok-button"
          :isDisabled="!saveButtonAvailable"
          :classProp="'primary'"
          @vasionButtonClicked="saveClick()"
        >
          SAVE
        </VasionButton>
      </div>
    </div>

    <md-dialog id="dirty-state-dialog" :md-active.sync="showDirtyStateDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog
        :message="'Your changes have not been saved, are you sure you want to cancel?'"
        @noButtonClick="toggleDirtyStateDialog()"
        @yesButtonClick="confirmationOfCancel()"
      />
    </md-dialog>

    <VasionSnackbar
      :showSnackbarBool.sync="snackbar.show"
      :snackbarImage="snackbar.image"
      :snackbarSubTitle="snackbar.subtitle"
      :snackbarTitle="snackbar.title"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { loaderColor } from "@/assets/js/styleConfig";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import { toBase64 } from "@/store/helperModules/storage.module";

const FilePond = vueFilePond(FilePondPluginImagePreview, FilePondPluginFileValidateType);

export default {
  name: "ConfigureImage",
  components: {
    Loading,
    FilePond,
  },
  props: {
    imageToModify: {
      type: Object,
      default: () => {},
      require: false,
    },
  },
  data: function () {
    return {
      applyTimestamp: false,
      imageUploaderActive: false,
      fileToUpload: "",
      filePondLabel: `<span class="filepond-drag">Drag your Image (JPG, TIFF, BMP or PNG) file here</span>
          <br/><br/>
        <span class="filepond-label-action">Or click to upload your image File</span>`,
      formFieldErrors: {
        name: false,
        fileToUpload: false,
      },
      isLoading: false,
      loaderColor: loaderColor,
      name: "",
      saveButtonAvailable: true,
      serverOptions: {
        process: this.processHandler,
        revert: this.removeHandler,
      },
      showDirtyStateDialog: false,
      snackbar: {
        show: false,
        image: false,
        subtitle: "",
        title: "",
      },
    };
  },
  computed: {
    formDataChanged() {
      let detectedChanges = false;
      if (Object.keys(this.imageToModify).length > 0) {
        if (this.name !== this.imageToModify.name) detectedChanges = true;
        if (this.applyTimestamp !== this.imageToModify.applyTimestamp) detectedChanges = true;
        if (this.fileToUpload.trim() !== "") detectedChanges = true;
      }
      return detectedChanges;
    },
    imageReceived() {
      return Object.keys(this.imageToModify).length > 0;
    },
    imageId() {
      return this.imageToModify.id ? this.imageToModify.id : 0;
    },
  },
  created: function () {
    this.errorText = "";
    this.imageUploaderActive = false;

    if (this.imageId <= 0) return;

    this.name = this.imageToModify.name;
    this.base64String = this.imageToModify.base64String;
    this.applyTimestamp = this.imageToModify.applyTimestamp;
  },
  methods: {
    afterFileProcessed() {
      this.saveButtonAvailable = true;
    },
    beforeAddFile() {
      this.saveButtonAvailable = false;
    },
    cancelClick() {
      if (this.formDataChanged) {
        this.toggleDirtyStateDialog();
        return;
      }
      this.$emit("cancel-click");
    },
    changeImageClick() {
      this.imageUploaderActive = true;
    },
    clearFieldError(field) {
      this.formFieldErrors[field] = false;
    },
    clearFieldErrors() {
      Object.keys(this.formFieldErrors).map((key) => (this.formFieldErrors[key] = false));
    },
    confirmationOfCancel() {
      this.toggleDirtyStateDialog();
      this.$emit("cancel-click");
    },
    async saveClick() {
      this.isLoading = true;
      this.clearFieldErrors();
      let errorText = "";

      if (this.name.trim() === "") {
        this.formFieldErrors.name = true;
        errorText += "Image Name cannot be empty.\n";
      }

      if (this.imageUploaderActive && this.fileToUpload.trim() === "") {
        this.formFieldErrors.fileToUpload = true;
        errorText += "An image must be selected.\n";
      }

      if (Object.values(this.formFieldErrors).some((x) => x)) {
        errorText += "Please fill out all required fields.";
      }

      if (errorText) {
        this.snackbar = {
          show: true,
          image: false,
          title: "Error",
          subtitle: errorText,
        };
        this.isLoading = false;
        return;
      }

      let payloadToSave = {
        ImageId: this.imageId,
        ImageName: this.name,
        Base64Image: this.fileToUpload,
        ApplyTimeStamp: this.applyTimestamp,
      };

      if (this.imageReceived && !this.imageUploaderActive) {
        payloadToSave.Base64Image = this.base64String;
      }

      const saveImageResponse = await this.$store.dispatch(
        "document/setAnnotationImage",
        payloadToSave
      );

      this.isLoading = false;

      if (!saveImageResponse || saveImageResponse.Value === "False") {
        this.snackbar = {
          show: true,
          image: false,
          title: "Error",
          subtitle: "Unable to save the Image.",
        };
        return;
      }

      this.$emit("on-image-saved");
    },
    // eslint-disable-next-line consistent-return
    removeHandler: function (source, load) {
      this.fileToUpload = "";
      load();
    },
    // eslint-disable-next-line consistent-return
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        this.fileToUpload = await toBase64(file);
        const stringOffset = ";base64,";
        const indexOfStringOffset = this.fileToUpload.indexOf(stringOffset);
        if (indexOfStringOffset >= 0) {
          this.fileToUpload = this.fileToUpload.substring(
            indexOfStringOffset + stringOffset.length
          );
        }

        progress(true, 0, 1024);
        load(file.name);

        return {
          abort: () => {
            if (abort !== undefined) {
              abort();
            }
          },
        };
      } catch (e) {
        console.warn(e);
      }
    },
    toggleApplyTimestamp() {
      this.applyTimestamp = !this.applyTimestamp;
    },
    toggleDirtyStateDialog() {
      this.showDirtyStateDialog = !this.showDirtyStateDialog;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
.main-div {
  width: 480px;
}
.bottom-div {
  text-align: right;
  vertical-align: middle;
}
.checkbox-div {
  margin: 12px 0px;
}
.input-style {
  margin: 5px 0px;
}
.img-container {
  text-align: center;
  img.existing-image {
    margin: auto;
    max-height: 180px;
    border: 1px solid;
  }
}
#dirty-state-dialog {
  z-index: 20;
}
</style>
